<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs v-model="selectedTab">
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
                @click="onClickTab(tab.value)"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"></v-tabs-items>
        <va-list
            :filters="filters"
            ref="list"
            class="asin-profit-list"
            disable-create
            disable-export
            :filter.sync="filter"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
        >
            <va-data-table
                ref="table"
                :fields="fields"
                class="asin-profit-list-main-table"
                disable-edit
                disable-clone
                disable-show
                disable-select
                disable-delete
                show-expand
                single-expand
            >
                <template v-slot:[`field.asin`]="{ value }">
                    {{ value }}
                </template>
                <template v-slot:[`field.sku`]="{ value }">
                    <div class="col-sku">
                        {{ value.includes(",") ? "Multiple" : value }}
                    </div>
                </template>
                <template v-slot:[`field.fnSku`]="{ value }">
                    <div class="col-fn-sku">
                        {{ value && value.includes(",") ? "Multiple" : value }}
                    </div>
                </template>
                <template v-slot:[`field.pics`]="{ value }">
                    <div class="col-pics">
                        {{ value ? value.map((i) => i.name).join(", ") : "" }}
                    </div>
                </template>
                <template v-slot:[`field.suppliers`]="{ value }">
                    <div class="col-suppliers">
                        {{ value ? value.map((i) => i.name).join(", ") : "" }}
                    </div>
                </template>
                <template v-slot:[`field.rev_30_day`]="{ value, item }">
                    ${{ value }} ({{ item.rev_sold_item_30_day }})
                </template>
                <template v-slot:[`field.rev_90_day`]="{ value, item }">
                    ${{ value }} ({{ item.rev_sold_item_90_day }})
                </template>

                <template v-slot:[`field.cog_30_day`]="{ value, item }">
                    {{ getDetailWithPercent(value, item, 30) }}
                </template>
                <template v-slot:[`field.cog_90_day`]="{ value, item }">
                    {{ getDetailWithPercent(value, item, 90) }}
                </template>

                <template
                    v-slot:[`field.profit_no_ad_30_day`]="{ value, item }"
                >
                    {{ getDetailWithPercent(value, item, 30) }}
                </template>
                <template
                    v-slot:[`field.profit_no_ad_90_day`]="{ value, item }"
                >
                    {{ getDetailWithPercent(value, item, 90) }}
                </template>

                <template v-slot:[`field.refund_30_day`]="{ value, item }">
                    ${{ Math.abs(value) }} ({{ item.refund_item_30_day }})
                </template>
                <template v-slot:[`field.refund_90_day`]="{ value, item }">
                    ${{ Math.abs(value) }} ({{ item.refund_item_90_day }})
                </template>
                <template v-slot:[`field.refund_rate_30_day`]="{ item }">
                    {{
                        Math.abs(
                            (item.refund_30_day * 100) / (item.rev_30_day || 1)
                        ).toFixed(2)
                    }}% ({{
                        (
                            (item.refund_item_30_day * 100) /
                            (item.rev_sold_item_30_day || 1)
                        ).toFixed(2)
                    }}%)
                </template>
                <template v-slot:[`field.refund_rate_90_day`]="{ item }">
                    {{
                        Math.abs(
                            (item.refund_90_day * 100) / (item.rev_90_day || 1)
                        ).toFixed(2)
                    }}% ({{
                        (
                            (item.refund_item_90_day * 100) /
                            (item.rev_sold_item_90_day || 1)
                        ).toFixed(2)
                    }}%)
                </template>

                <template v-slot:[`field.other_cost_30_day`]="{ item }">
                    ${{ item.other_cost_30_day }}
                </template>
                <template v-slot:[`field.other_cost_90_day`]="{ item }">
                    ${{ item.other_cost_30_day }}
                </template>
                <template v-slot:[`field.other_earnings_30_day`]="{ item }">
                    ${{ item.other_earnings_30_day }}
                </template>
                <template v-slot:[`field.other_earnings_90_day`]="{ item }">
                    ${{ item.other_earnings_90_day }}
                </template>

                <template v-slot:[`field.ad_cost_30_day`]="{ value, item }">
                    {{ getDetailWithPercent(value, item, 30) }}
                </template>
                <template v-slot:[`field.ad_cost_90_day`]="{ value, item }">
                    {{ getDetailWithPercent(value, item, 90) }}
                </template>

                <template v-slot:[`field.net_profit_30_day`]="{ value, item }">
                    {{ getDetailWithPercent(value, item, 30) }}
                </template>
                <template v-slot:[`field.net_profit_90_day`]="{ value, item }">
                    {{ getDetailWithPercent(value, item, 90) }}
                </template>
                <template v-slot:[`field.label`]="{ value }">
                    <v-chip text-color="white" :color="getLabelColor(value)">
                        {{ value }}
                    </v-chip>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <v-data-table
                        v-if="item.children.length"
                        hide-default-header
                        hide-default-footer
                        class="asin-profit-list-item-headers"
                        :headers="item_headers"
                        :items="item.children"
                    >
                        <template v-slot:item.first="{}">
                            <button
                                type="button"
                                class="
                                    v-icon
                                    v-data-table__expand-icon
                                    v-icon--link
                                    mdi mdi-square-small
                                "
                            ></button>
                        </template>
                        <template v-slot:item.small_image="{}"> </template>
                        <template v-slot:item.sku="{ value }">
                            <div class="col-sku">
                                {{ value }}
                            </div>
                        </template>
                        <template v-slot:item.fnSku="{ value }">
                            <div class="col-fn-sku">
                                {{ value }}
                            </div>
                        </template>
                        <template v-slot:item.pics="{ value }">
                            <div class="col-pics">
                                {{
                                    value
                                        ? value.map((i) => i.name).join(", ")
                                        : ""
                                }}
                            </div>
                        </template>
                        <template v-slot:item.suppliers="{ value }">
                            <div class="col-suppliers">
                                {{
                                    value
                                        ? value.map((i) => i.name).join(", ")
                                        : ""
                                }}
                            </div>
                        </template>
                        <template v-slot:item.rev_30_day="{ value, item }">
                            ${{ value }} ({{ item.rev_sold_item_30_day }})
                        </template>
                        <template v-slot:item.rev_90_day="{ value, item }">
                            ${{ value }} ({{ item.rev_sold_item_90_day }})
                        </template>
                        <template v-slot:item.cog_30_day="{ value, item }">
                            {{ getDetailWithPercent(value, item, 30) }}
                        </template>
                        <template v-slot:item.cog_90_day="{ value, item }">
                            {{ getDetailWithPercent(value, item, 90) }}
                        </template>
                        <template
                            v-slot:item.profit_no_ad_30_day="{ value, item }"
                        >
                            {{ getDetailWithPercent(value, item, 30) }}
                        </template>
                        <template
                            v-slot:item.profit_no_ad_90_day="{ value, item }"
                        >
                            {{ getDetailWithPercent(value, item, 90) }}
                        </template>
                        <template v-slot:item.refund_30_day="{ value, item }">
                            ${{ Math.abs(value) }} ({{
                                item.refund_item_30_day
                            }})
                        </template>
                        <template v-slot:item.refund_90_day="{ value, item }">
                            ${{ Math.abs(value) }} ({{
                                item.refund_item_90_day
                            }})
                        </template>
                        <template v-slot:item.refund_rate_30_day="{ item }">
                            {{
                                Math.abs(
                                    (item.refund_30_day * 100) /
                                        (item.rev_30_day || 1)
                                ).toFixed(2)
                            }}% ({{
                                (
                                    (item.refund_item_30_day * 100) /
                                    (item.rev_sold_item_30_day || 1)
                                ).toFixed(2)
                            }}%)
                        </template>
                        <template v-slot:item.refund_rate_90_day="{ item }">
                            {{
                                Math.abs(
                                    (item.refund_90_day * 100) /
                                        (item.rev_90_day || 1)
                                ).toFixed(2)
                            }}% ({{
                                (
                                    (item.refund_item_90_day * 100) /
                                    (item.rev_sold_item_90_day || 1)
                                ).toFixed(2)
                            }}%)
                        </template>

                        <template v-slot:item.other_cost_30_day="{ item }">
                            ${{ item.other_cost_30_day }}
                        </template>
                        <template v-slot:item.other_cost_90_day="{ item }">
                            ${{ item.other_cost_30_day }}
                        </template>
                        <template v-slot:item.other_earnings_30_day="{ item }">
                            ${{ item.other_earnings_30_day }}
                        </template>
                        <template v-slot:item.other_earnings_90_day="{ item }">
                            ${{ item.other_earnings_90_day }}
                        </template>
                        <template v-slot:item.ad_cost_30_day="{ value, item }">
                            {{ getDetailWithPercent(value, item, 30) }}
                        </template>
                        <template v-slot:item.ad_cost_90_day="{ value, item }">
                            {{ getDetailWithPercent(value, item, 90) }}
                        </template>
                        <template
                            v-slot:item.net_profit_30_day="{ value, item }"
                        >
                            {{ getDetailWithPercent(value, item, 30) }}
                        </template>
                        <template
                            v-slot:item.net_profit_90_day="{ value, item }"
                        >
                            {{ getDetailWithPercent(value, item, 90) }}
                        </template>
                        <template v-slot:item.label="{ value }">
                            <v-chip
                                text-color="white"
                                :color="getLabelColor(value)"
                            >
                                {{ value }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </template>
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import { tz, sourceTz } from "@/constants/Time";
import MarketplaceIds from "@/constants/MarketplaceIds";
import SaleInventoryLabels, {
    SaleInventoryLabelColorMap,
} from "@/constants/SaleInventoryLabels";
import Export from "@/resources/sale-inventory/Export";

export default {
    mixins: [Export],
    props: ["resource", "title"],
    components: {},
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            selectedTab: 0,
            isGenerating: false,
            marketplaceIdKey: "main_marketplace_id",
            filter: {
                second_marketplace: 0,
            },
            sort: {
                by: ["rev_30_day"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { label: "ASIN", source: "asin", type: "text", alwaysOn: true },
                { label: "SKU", source: "sku", type: "text", alwaysOn: true },
                {
                    label: "FnSKU",
                    source: "fnSku",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    label: "Supplier",
                    source: "supplier_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "Supplier",
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
                {
                    source: "marketplace_id",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
                {
                    source: "label",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: SaleInventoryLabels,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                {
                    label: "Image",
                    source: "small_image",
                    attributes: {
                        src: "URL",
                    },
                    type: "image",
                    sortable: false,
                },
                { label: "ASIN", source: "asin", sortable: true },
                { label: "Label", source: "label", sortable: false },
                { label: "SKU", source: "sku", sortable: true },
                { label: "FnSKU", source: "fnSku", sortable: true },
                {
                    label: "Supplier",
                    source: "suppliers",
                    sortable: false,
                },
                {
                    label: "PIC",
                    source: "pics",
                    sortable: false,
                },
                {
                    label: "Rev",
                    source: "rev_30_day",
                    sortable: true,
                },
                {
                    label: "Cogs",
                    source: "cog_30_day",
                    sortable: true,
                },
                {
                    label: "Profit (No Ad)",
                    source: "profit_no_ad_30_day",
                    sortable: true,
                },
                {
                    label: "Refund",
                    source: "refund_30_day",
                    sortable: true,
                },
                {
                    label: "Refund rate",
                    source: "refund_rate_30_day",
                    sortable: true,
                },
                {
                    label: "Other cost",
                    source: "other_cost_30_day",
                    sortable: true,
                },
                {
                    label: "Other earnings",
                    source: "other_earnings_30_day",
                    sortable: true,
                },
                {
                    label: "Ad cost",
                    source: "ad_cost_30_day",
                    sortable: true,
                },
                {
                    label: "NET Profit",
                    source: "net_profit_30_day",
                    sortable: true,
                },
                {
                    label: "Rev",
                    source: "rev_90_day",
                    sortable: true,
                },
                {
                    label: "Cogs",
                    source: "cog_90_day",
                    sortable: true,
                },
                {
                    label: "Profit (No Ad)",
                    source: "profit_no_ad_90_day",
                    sortable: true,
                },
                {
                    label: "Refund",
                    source: "refund_90_day",
                    sortable: true,
                },
                {
                    label: "Refund rate",
                    source: "refund_rate_90_day",
                    sortable: true,
                },
                {
                    label: "Other cost",
                    source: "other_cost_90_day",
                    sortable: true,
                },
                {
                    label: "Other earnings",
                    source: "other_earnings_90_day",
                    sortable: true,
                },
                {
                    label: "Ad cost",
                    source: "ad_cost_90_day",
                    sortable: true,
                },
                {
                    label: "NET Profit",
                    source: "net_profit_90_day",
                    sortable: true,
                },
            ],
            item_headers: [
                { text: "first", value: "first", sortable: false },
                { text: "small_image", value: "small_image", sortable: false },
                { text: "asin", value: "asin", sortable: false },
                { text: "label", value: "label", sortable: false },
                { text: "SKU", value: "sku", sortable: true },
                { text: "FnSKU", value: "fnSku", sortable: true },
                { text: "Supplier", value: "suppliers", sortable: true },
                { text: "PIC", value: "pics", sortable: true },
                {
                    text: "Rev last 30 days",
                    value: "rev_30_day",
                    sortable: true,
                },
                {
                    text: "Cogs last 30 days",
                    value: "cog_30_day",
                    sortable: true,
                },
                {
                    text: "Profit (no Ad) last 30 days",
                    value: "profit_no_ad_30_day",
                    sortable: true,
                },
                {
                    text: "Refund last 30 days",
                    value: "refund_30_day",
                    sortable: true,
                },
                {
                    text: "Refund rate last 30 days",
                    value: "refund_rate_30_day",
                    sortable: true,
                },
                {
                    text: "Other cost last 30 days",
                    value: "other_cost_30_day",
                    sortable: true,
                },
                {
                    text: "Other earnings last 30 days",
                    value: "other_earnings_30_day",
                    sortable: true,
                },
                {
                    text: "Ad cost last 30 days",
                    value: "ad_cost_30_day",
                    sortable: true,
                },
                {
                    text: "Rev last 90 days",
                    value: "rev_90_day",
                    sortable: true,
                },
                {
                    text: "Cogs last 90 days",
                    value: "cog_90_day",
                    sortable: true,
                },
                {
                    text: "Profit (no Ad) last 90 days",
                    value: "profit_no_90_day",
                    sortable: true,
                },
                {
                    text: "Refund last 90 days",
                    value: "refund_90_day",
                    sortable: true,
                },
                {
                    text: "Refund rate last 90 days",
                    value: "refund_rate_90_day",
                    sortable: true,
                },
                {
                    text: "Other cost last 90 days",
                    value: "other_cost_90_day",
                    sortable: true,
                },
                {
                    text: "Other earnings last 90 days",
                    value: "other_earnings_90_day",
                    sortable: true,
                },
                {
                    text: "Ad cost last 90 days",
                    value: "ad_cost_90_day",
                    sortable: true,
                },
                {
                    text: "NET profit last 30 days",
                    value: "net_profit_30_day",
                    sortable: true,
                },
                {
                    text: "NET profit last 90 days",
                    value: "net_profit_90_day",
                    sortable: true,
                },
            ],
            isGroupedHeader: false,
            selected: false,
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            if (id) {
                const mwsConfigurationOption =
                    this.mwsConfigurationOptions.find(
                        (mwsConfiguration) => mwsConfiguration.value === id
                    );
                this.filter["marketplace_id"] =
                    mwsConfigurationOption[this.marketplaceIdKey];
            } else {
                this.filter["marketplace_id"] = "";
            }
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        buildHeader(newValue) {
            if (!newValue.length) {
                this.isGroupedHeader = false;
                return;
            }
            if (this.isGroupedHeader) {
                return;
            }
            const table = this.$refs.table.$el.querySelector("table");
            const defaultColGroup = table.querySelector("colgroup");
            defaultColGroup.span = 3;
            defaultColGroup.childNodes.forEach((child, index) => {
                if (index < 3) {
                    return;
                }
                child.remove();
            });
            const col1 = document.createElement("col");
            table.insertBefore(col1, defaultColGroup);
            // image,asin,label,sku,fnSku,pic,supplier
            const colGroup1 = document.createElement("colgroup");
            colGroup1.span = 7;
            colGroup1.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup1, defaultColGroup);
            // Last 30
            const colGroup2 = document.createElement("colgroup");
            colGroup2.span = 9;
            colGroup2.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup2, defaultColGroup);
            // Last 90
            const colGroup3 = document.createElement("colgroup");
            colGroup3.span = 9;
            colGroup3.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup3, defaultColGroup);
            defaultColGroup.parentNode.insertBefore(
                colGroup3,
                defaultColGroup.nextSibling
            );
            //
            const thead = table.tHead;
            const defaultHeadRow = thead.rows[0];
            defaultHeadRow.cells[0].remove();
            const newHeadRow = thead.insertRow(0);
            const newHeadRowCell1 = newHeadRow.insertCell(0);
            newHeadRowCell1.rowSpan = 2;
            const newHeadRowCell2 = newHeadRow.insertCell(1);
            newHeadRowCell2.scope = "colgroup";
            newHeadRowCell2.colSpan = 7;
            const newHeadRowCell3 = newHeadRow.insertCell(2);
            newHeadRowCell3.scope = "colgroup";
            newHeadRowCell3.className = "text-center";
            newHeadRowCell3.colSpan = 9;
            newHeadRowCell3.innerText = "Last 30 days";
            const newHeadRowCell4 = newHeadRow.insertCell(3);
            newHeadRowCell4.scope = "colgroup";
            newHeadRowCell4.className = "text-center";
            newHeadRowCell4.colSpan = 9;
            newHeadRowCell4.innerText = "Last 90 days";
            this.isGroupedHeader = true;
        },
        getLabelColor(label) {
            return SaleInventoryLabelColorMap[label];
        },
        getDetailWithPercent(value, item, day) {
            if (!item[`rev_${day}_day`]) {
                return `$${value}`;
            }

            const percent = (value * 100) / item[`rev_${day}_day`];

            if (!percent) {
                return `$${value}`;
            }

            return `$${value} (${percent.toFixed(2)}%)`;
        },
    },
    beforeMount() {},
    mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            (newValue) => this.buildHeader(newValue)
        );
    },
};
</script>
<style lang="sass">
$filter-height: 80px
$number-col-width: 80px
$image-col-width: 108px
$rev-color: antiquewhite
$cog-color: moccasin
$profit-no-ad-color: aliceblue
$refund-color: floralwhite
$ad-cost-color: powderblue
$net-profit-color: darkseagreen

.menuable__content__active
    z-index: 9999 !important

.asin-profit-list
    > header
        height: $filter-height !important

        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important

    .v-data-table-header
        tr
            th:nth-child(n+8):nth-child(-n+25)
                width: $number-col-width !important
                min-width: $number-col-width !important

    > form
        > div
            > div
                height: 65vh
                overflow-y: auto

                > table
                    > thead
                        position: sticky
                        top: 0
                        background: white
                        z-index: 99

                        //td:nth-child(3), th:nth-child(8), th:nth-child(14)
                        //    background: $rev-color
                        //
                        //td:nth-child(4), th:nth-child(9), th:nth-child(15)
                        //    background: $cog-color
                        //
                        //td:nth-child(5), th:nth-child(10), th:nth-child(16)
                        //    background: $profit-no-ad-color
                        //
                        //td:nth-child(6), th:nth-child(11), th:nth-child(17)
                        //    background: $refund-color
                        //
                        //td:nth-child(7), th:nth-child(12), th:nth-child(18)
                        //    background: $ad-cost-color
                        //
                        //td:nth-child(8), th:nth-child(13), th:nth-child(19)
                        //    background: $net-profit-color
                    td:nth-child(3), th:nth-child(8), th:nth-child(9), th:nth-child(10), th:nth-child(11), th:nth-child(12), th:nth-child(13), th:nth-child(14), th:nth-child(15), th:nth-child(16)
                        background: $rev-color
                    td:nth-child(4), th:nth-child(17), th:nth-child(18), th:nth-child(19), th:nth-child(20), th:nth-child(21), th:nth-child(22), th:nth-child(23), th:nth-child(24), th:nth-child(25)
                            background: $cog-color

                    > colgroup:nth-child(3)
                        background: $rev-color

                    > colgroup:nth-child(4)
                        background: $cog-color

                    //> colgroup:nth-child(5)
                    //    background: $profit-no-ad-color
                    //
                    //> colgroup:nth-child(6)
                    //    background: $refund-color
                    //
                    //> colgroup:nth-child(7)
                    //    background: $ad-cost-color
                    //
                    //> colgroup:nth-child(8)
                    //    background: $net-profit-color

                    > tbody
                        > tr
                            > td
                                button
                                    background: transparent !important

                            td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6),
                            td:nth-child(7), td:nth-child(8)
                                width: $image-col-width
                                min-width: $image-col-width
                                max-width: $image-col-width

                            td
                                .col-pics, .col-suppliers
                                    min-width: 60px !important

                            td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13),
                            td:nth-child(14), td:nth-child(15), td:nth-child(16), td:nth-child(17), td:nth-child(18),
                            td:nth-child(19), td:nth-child(20), td:nth-child(21), td:nth-child(22), td:nth-child(23),
                            td:nth-child(24), td:nth-child(25), td:nth-child(26), td:nth-child(27)
                                text-align: right !important

                            td:last-child
                                //width: 85px !important

                                label
                                    display: none !important

                            //td:nth-child(14), td:nth-child(15)
                            //    background: $cog-color
                            td:nth-child(3), td:nth-child(4)
                                background: white

                            table
                                //td:nth-child(9), td:nth-child(15)
                                //    background: $rev-color
                                //td:nth-child(10), td:nth-child(11), td:nth-child(12)
                                //    background: $cog-color
                                //td:nth-child(13), td:nth-child(14)
                                //    background: $profit-no-ad-color
                                //td:nth-child(16)
                                //    background: $refund-color
                                //td:nth-child(17), td:nth-child(18)
                                //    background: $ad-cost-color
                                //td:nth-child(19) td:nth-child(20)
                                //    background: $net-profit-color

                                td:nth-child(n+8):nth-child(-n+27)
                                    width: $number-col-width !important
                                    min-width: $number-col-width !important

.v-toolbar__title a
    color: white !important
</style>
