<script>
import { ALL, COGS } from "@/constants/CogCatalogItemExportTypes";
import { getAsinAge } from "@/constants/AsinAgeTypes";

export default {
    data() {
        return {
            file: null,
            isGenerating: false,
            fileLink: "#",
            entity: "CogCatalogItem",
            extraExportParams: {},
        };
    },
    methods: {
        getExportHeader(type = ALL) {
            if (type === ALL) {
                return [
                    "No",
                    "Brand",
                    "marketplace_id",
                    "sku",
                    "fnSku",
                    "ASIN",
                    "supplier.name",
                    "pic.name",
                    "cog",
                    "currentQuantity",
                    "totalCog",
                    "original_price",
                    "listing_price",
                    "referral_fee",
                    "fba_fee",
                    "gp",
                    "sale_label",
                    "tags",
                    "sum_sale_30_day",
                    "asin_age",
                    "created_at",
                    "BRS",
                    "Product type",
                    "Title",
                ];
            }
            //No,Brand,marketplace_id,sku,cog,quantity,shipment_code
            if (type === COGS) {
                return [
                    "No",
                    "Brand",
                    "marketplace_id",
                    "sku",
                    "cog",
                    "quantity",
                    "shipment_code",
                ];
            }
            //Brand,marketplace_id,sku,supplier.name,pic.name,cog,original_price,note,barcode_link,image_link
            return [
                "Brand",
                "marketplace_id",
                "sku",
                "supplier.name",
                "pic.name",
                "cog",
                "original_price",
                "note",
                "barcode_link",
                "image_link",
            ];
        },
        getExportRowData(item, index, brandMap, type = COGS) {
            if (type === ALL) {
                let tags = [
                    item.is_low_price ? "LowPrice" : false,
                    item.is_neg_gp ? "NegGP" : false,
                ]
                    .filter(Boolean)
                    .join("|");
                return [
                    [
                        index,
                        brandMap[item.parent_id],
                        item.marketplace_id,
                        item.sku,
                        item.fnSku,
                        item.ASIN,
                        item.supplier?.name,
                        item.pic?.name,
                        item.cog || 0,
                        item.currentQuantity || 0,
                        item.totalCog || 0,
                        item.original_price || 0,
                        item.listing_price || 0,
                        item.referral_fee || 0,
                        item.fba_fee || 0,
                        item.gp || 0,
                        item.label,
                        tags,
                        parseInt(item.sum_sale_30_day, 10),
                        getAsinAge(item, "asin_created_at"),
                        item.created_at,
                        item.sales_ranking,
                        item.product_type,
                        item.product_title,
                    ],
                ];
            }

            if (type === COGS) {
                return [
                    [
                        index,
                        brandMap[item.parent_id],
                        item.marketplace_id,
                        item.sku,
                        item.cog,
                        item.quantity || 0,
                    ],
                ];
            }
            return [
                [
                    brandMap[item.parent_id],
                    item.marketplace_id,
                    item.sku,
                    item.supplier?.name,
                    item.pic?.name,
                    item.cog || 0,
                    item.original_price || 0,
                    item.note || "",
                    item.barcode_link || item.barcode_file || "",
                    item.image_link || item.image_file || "",
                ],
            ];
        },
        async generateLink(type = COGS) {
            this.isGenerating = true;
            const response = await this.$store.dispatch(
                `${this.entity}/getList`,
                {
                    pagination: {
                        page: 1,
                        perPage: 9000000,
                    },
                    filter: this.filter,
                    extraParams: this.extraExportParams,
                }
            );
            const items = response.data;
            const brandMap = {};
            this.mwsConfigurationOptions.forEach((configuration) => {
                if (!configuration.value) {
                    return;
                }
                brandMap[configuration.value] = configuration.text;
            });
            const rows = [this.getExportHeader(type, items)];
            items.forEach((item, index) => {
                rows.push(
                    ...this.getExportRowData(item, index + 1, brandMap, type)
                );
            });

            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            this.fileLink = encodeURI(csvContent);
            this.isGenerating = false;
        },
        csvToArray(str, delimiter = ",") {
            // slice from start of text to the first \n index
            // use split to create an array from string by delimiter
            const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

            // slice from \n index + 1 to the end of the text
            // use split to create an array of each csv value row
            const rows = str.slice(str.indexOf("\n") + 1).split("\n");

            // Map the rows
            // split values from each row into an array
            // use headers.reduce to create an object
            // object properties derived from headers:values
            // the object passed as an element of the array
            // return the array
            return rows.map(function (row) {
                const values = row.split(delimiter);
                return headers.reduce(function (object, header, index) {
                    object[header] = values[index];
                    return object;
                }, {});
            });
        },
    },
};
</script>
