var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"asin-profit-list",attrs:{"filters":_vm.filters,"disable-create":"","disable-export":"","filter":_vm.filter,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc},on:{"update:filter":function($event){_vm.filter=$event},"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)}}},[_c('va-data-table',{ref:"table",staticClass:"asin-profit-list-main-table",attrs:{"fields":_vm.fields,"disable-edit":"","disable-clone":"","disable-show":"","disable-select":"","disable-delete":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"field.asin",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"field.sku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-sku"},[_vm._v(" "+_vm._s(value.includes(",") ? "Multiple" : value)+" ")])]}},{key:"field.fnSku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-fn-sku"},[_vm._v(" "+_vm._s(value && value.includes(",") ? "Multiple" : value)+" ")])]}},{key:"field.pics",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-pics"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"field.suppliers",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-suppliers"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"field.rev_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(value)+" ("+_vm._s(item.rev_sold_item_30_day)+") ")]}},{key:"field.rev_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(value)+" ("+_vm._s(item.rev_sold_item_90_day)+") ")]}},{key:"field.cog_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"field.cog_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"field.profit_no_ad_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"field.profit_no_ad_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"field.refund_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(Math.abs(value))+" ("+_vm._s(item.refund_item_30_day)+") ")]}},{key:"field.refund_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(Math.abs(value))+" ("+_vm._s(item.refund_item_90_day)+") ")]}},{key:"field.refund_rate_30_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs( (item.refund_30_day * 100) / (item.rev_30_day || 1) ).toFixed(2))+"% ("+_vm._s(( (item.refund_item_30_day * 100) / (item.rev_sold_item_30_day || 1) ).toFixed(2))+"%) ")]}},{key:"field.refund_rate_90_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs( (item.refund_90_day * 100) / (item.rev_90_day || 1) ).toFixed(2))+"% ("+_vm._s(( (item.refund_item_90_day * 100) / (item.rev_sold_item_90_day || 1) ).toFixed(2))+"%) ")]}},{key:"field.other_cost_30_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_cost_30_day)+" ")]}},{key:"field.other_cost_90_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_cost_30_day)+" ")]}},{key:"field.other_earnings_30_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_earnings_30_day)+" ")]}},{key:"field.other_earnings_90_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_earnings_90_day)+" ")]}},{key:"field.ad_cost_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"field.ad_cost_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"field.net_profit_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"field.net_profit_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"field.label",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getLabelColor(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.children.length)?_c('v-data-table',{staticClass:"asin-profit-list-item-headers",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.item_headers,"items":item.children},scopedSlots:_vm._u([{key:"item.first",fn:function(ref){return [_c('button',{staticClass:"\n                                v-icon\n                                v-data-table__expand-icon\n                                v-icon--link\n                                mdi mdi-square-small\n                            ",attrs:{"type":"button"}})]}},{key:"item.small_image",fn:function(ref){return undefined}},{key:"item.sku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-sku"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.fnSku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-fn-sku"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.pics",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-pics"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"item.suppliers",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-suppliers"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"item.rev_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(value)+" ("+_vm._s(item.rev_sold_item_30_day)+") ")]}},{key:"item.rev_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(value)+" ("+_vm._s(item.rev_sold_item_90_day)+") ")]}},{key:"item.cog_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"item.cog_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"item.profit_no_ad_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"item.profit_no_ad_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"item.refund_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(Math.abs(value))+" ("+_vm._s(item.refund_item_30_day)+") ")]}},{key:"item.refund_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" $"+_vm._s(Math.abs(value))+" ("+_vm._s(item.refund_item_90_day)+") ")]}},{key:"item.refund_rate_30_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs( (item.refund_30_day * 100) / (item.rev_30_day || 1) ).toFixed(2))+"% ("+_vm._s(( (item.refund_item_30_day * 100) / (item.rev_sold_item_30_day || 1) ).toFixed(2))+"%) ")]}},{key:"item.refund_rate_90_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs( (item.refund_90_day * 100) / (item.rev_90_day || 1) ).toFixed(2))+"% ("+_vm._s(( (item.refund_item_90_day * 100) / (item.rev_sold_item_90_day || 1) ).toFixed(2))+"%) ")]}},{key:"item.other_cost_30_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_cost_30_day)+" ")]}},{key:"item.other_cost_90_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_cost_30_day)+" ")]}},{key:"item.other_earnings_30_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_earnings_30_day)+" ")]}},{key:"item.other_earnings_90_day",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_earnings_90_day)+" ")]}},{key:"item.ad_cost_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"item.ad_cost_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"item.net_profit_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 30))+" ")]}},{key:"item.net_profit_90_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDetailWithPercent(value, item, 90))+" ")]}},{key:"item.label",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getLabelColor(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)}):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }