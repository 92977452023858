<template>
    <div>
        <base-material-card :icon="resource.icon" :title="title">
            <div
                class="text-right"
                style="position: absolute; right: 10px; z-index: 10"
            >
                <calendar-filter
                    @update="(val) => update(val)"
                ></calendar-filter>
            </div>
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <v-row>
                <v-col class="text-center font-weight-bold">
                    <span>
                        Total: {{ orderCount }} orders,
                        {{ totalOrderedQuantity }} Unit sold,
                        {{ totalOrderedAmount }} $.
                    </span>
                    <br />
                    <span>Latest updated at: {{ latestUpdatedAt }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <va-list
                        resource="Order"
                        ref="list"
                        class="order-list border-e-lg"
                        :filters="filters"
                        :filter.sync="filter"
                        :sort-by.sync="sort.by"
                        :sort-desc.sync="sort.desc"
                        disable-create
                        disable-export
                    >
                        <template v-slot:actions>
                            <div class="actions-wrapper">
                                <va-action-button
                                    label="Export"
                                    icon="mdi-download"
                                    text
                                    :loading="isGenerating"
                                    @click="exportAll"
                                ></va-action-button>
                                <a
                                    :href="fileLink"
                                    ref="fileLinkElement"
                                    class="d-none"
                                    :download="exportFileName"
                                    >Download exported file.</a
                                >
                            </div>
                        </template>
                        <va-data-table
                            :fields="fields"
                            disable-actions
                            disable-select
                            show-expand
                        >
                            <template v-slot:expanded-item="{ item }">
                                <json-viewer
                                    :value="item"
                                    :expand-depth="5"
                                    copyable
                                    sort
                                ></json-viewer>
                            </template>
                            <!--                            <template v-slot:[`field.PurchaseDate`]="{ value }">-->
                            <!--                                {{-->
                            <!--                                    value | moment("timezone", sourceTz, "LLL")-->
                            <!--                                }}-->
                            <!--                            </template>-->
                            <template
                                v-slot:[`field.AmazonOrderId`]="{ value, item }"
                            >
                                <a @click="openOrderReport(item)">{{
                                    value
                                }}</a>
                            </template>
                        </va-data-table>
                    </va-list>
                </v-col>
                <v-col>
                    <va-list
                        resource="OrderForecastItem"
                        ref="item_list"
                        class="order-forecast-item-list"
                        :filters="itemFilters"
                        :filter.sync="itemFilter"
                        :sort-by.sync="itemSort.by"
                        :sort-desc.sync="itemSort.desc"
                        :items-per-page="10"
                        disable-create
                        disable-export
                        :include="[
                            'cogCatalogItem.product',
                            'cogCatalogItem.pic',
                        ]"
                    >
                        <va-data-table
                            :fields="itemFields"
                            disable-actions
                            disable-select
                            show-expand
                        >
                            <template v-slot:[`field.ASIN`]="{ value, item }">
                                <a @click="openAsinReport(item)">{{ value }}</a>
                            </template>
                            <template
                                v-slot:[`field.ImageAttribute`]="{
                                    value,
                                    item,
                                }"
                            >
                                <img
                                    width="75"
                                    v-if="value && value.SmallImage"
                                    :alt="value.SmallImage.URL"
                                    :src="getImage(item, value.SmallImage.URL)"
                                    :onerror="`this.onerror=null;this.src='${value.SmallImage.URL}';`"
                                />
                            </template>
                        </va-data-table>
                    </va-list>
                </v-col>
            </v-row>
            <AsinProfile
                v-model="dialog"
                :selected="selected"
                :marketplace-id="
                    this.filter ? this.filter.marketplace_id : null
                "
            ></AsinProfile>
            <OrderReport
                v-model="orderDialog"
                :selected="selectedOrder"
            ></OrderReport>
        </base-material-card>
    </div>
</template>

<script>
import maxBy from "lodash/maxBy";
import get from "lodash/get";
import { sourceTz } from "@/constants/Time";
import moment from "moment-timezone";
import OrderStatuses from "@/constants/OrderStatuses";
import CalendarFilter from "@/components/CalendarFilter.vue";
import AsinProfile from "@/components/AsinProfile.vue";
import OrderReport from "@/components/OrderReport.vue";
import Export from "@/resources/cog-catalog-item/Export.vue";

export default {
    mixins: [Export],
    props: {
        resource: {
            type: Object,
        },
        mwsConfigurationId: {
            type: Number,
        },
        title: {
            type: String,
        },
        perPage: {
            type: Number,
            default: 15,
        },
    },
    components: { AsinProfile, CalendarFilter, OrderReport },
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
                this.itemFilter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;

                const itemFilter = this.itemFilters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                itemFilter.attributes.choices = [...newVal];
            },
        },
    },
    data() {
        return {
            entity: "Order",
            exportFileName: "orders.csv",
            extraExportParams: { export: 1 },
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            selectedTab: 0,
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
                mws_configuration_id: this.mwsConfigurationId
                    ? [this.mwsConfigurationId]
                    : [],
            },
            sort: {
                by: ["PurchaseDate"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "OrderStatus",
                    type: "select",
                    attributes: {
                        choices: OrderStatuses,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "SalesChannel",
                    type: "select",
                    attributes: {
                        choices: [],
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                { source: "AmazonOrderId", sortable: true },
                { source: "PurchaseDate", sortable: true },
                { source: "OrderStatus", sortable: true },
                { source: "SalesChannel", sortable: true },
                { source: "shipment_date", sortable: true },
            ],
            itemSort: {
                by: ["QuantityOrdered"],
                desc: [true],
            },
            itemFilter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
                mws_configuration_id: this.mwsConfigurationId
                    ? [this.mwsConfigurationId]
                    : [],
            },
            itemFilters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
            ],
            itemFields: [
                {
                    label: "Image",
                    source: "ImageAttribute",
                    align: "start",
                    sortable: false,
                },
                {
                    label: "ASIN",
                    source: "ASIN",
                    align: "start",
                    sortable: true,
                },
                {
                    label: "SKU",
                    source: "SellerSKU",
                    align: "start",
                    sortable: true,
                },
                {
                    label: "PIC",
                    source: "pic",
                    align: "start",
                    sortable: false,
                },
                {
                    label: "Unit Sold",
                    source: "QuantityOrdered",
                    align: "right",
                    sortable: true,
                },
            ],
            orderCount: 0,
            totalOrderedQuantity: 0,
            totalOrderedAmount: 0,
            latestUpdatedAt: "-",
            dialog: false,
            selected: false,
            orderDialog: false,
            selectedOrder: false,
        };
    },
    mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            () => {
                this.getSummary(this.filter);
            }
        );
        this.$watch(
            () => {
                return this.$refs.item_list.listState.items;
            },
            () => {
                this.getSummary(this.itemFilter);
            }
        );
    },
    methods: {
        getImage(item, defaultImage) {
            if (!item.image_file) {
                return defaultImage;
            }
            return item.image_file;
        },
        update(value) {
            this.filter = {
                ...this.filter,
                date_between: `${value.formattedStartDate},${value.formattedEndDate}`,
            };
            this.itemFilter = {
                ...this.itemFilter,
                date_between: `${value.formattedStartDate},${value.formattedEndDate}`,
            };
        },
        async getSummary(filter) {
            const { data } = await this.$store.dispatch(
                "OrderForecastSummary/getList",
                {
                    filter: {
                        ...filter,
                    },
                }
            );

            const {
                quantity_ordered,
                order_count,
                amount_ordered,
                latest_updated_at,
                sale_channels,
            } = data;

            this.orderCount = order_count;
            this.totalOrderedQuantity = quantity_ordered;
            this.totalOrderedAmount = amount_ordered;
            this.latestUpdatedAt = latest_updated_at;
            this.filters.find(
                (filter) => filter.source === "SalesChannel"
            ).attributes.choices = sale_channels.map((sale_channel) => ({
                text: sale_channel,
                value: sale_channel,
            }));
        },
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            this.$refs.list.fetchData();
            this.itemFilter["mws_configuration_id"] = id ? id : "";
            this.$refs.item_list.fetchData();
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        openAsinReport(item) {
            this.dialog = true;
            this.selected = {
                ...item,
                asin: item.ASIN,
                image:
                    item.ImageAttribute && item.ImageAttribute.SmallImage
                        ? this.getImage(
                              item,
                              item.ImageAttribute.SmallImage.URL
                          )
                        : "#",
                pics: [{ name: item.pic }],
                suppliers: [],
            };
        },
        openOrderReport(item) {
            this.orderDialog = true;
            this.selectedOrder = {
                ...item,
            };
        },
        async exportAll() {
            this.exportFileName = "orders.csv";
            await this.generateLink();
            this.$refs.fileLinkElement.click();
        },
        getExportHeader(type, items) {
            let itemArr = [];
            for (
                let displayIndex = 1;
                displayIndex <=
                get(
                    maxBy(items, "report.0.item_count"),
                    "report.0.item_count",
                    0
                );
                displayIndex++
            ) {
                itemArr.push(`ASIN_${displayIndex}`);
                itemArr.push(`SKU_${displayIndex}`);
                itemArr.push(`QTY_${displayIndex}`);
                itemArr.push(`Principal_${displayIndex}`);
            }

            return [
                "No",
                "AmazonOrderId",
                "PurchaseDate",
                "OrderStatus",
                "SalesChannel",
                "ShipmentDate",
                "NETProfit",
                "RefundDate",
                "RefundAmount",
                ...itemArr,
            ];
        },
        getExportRowData(item, index) {
            let items = [];
            item.report[0].items.forEach((i) => {
                items.push([i.ASIN, i.sku, i.qty, i.principal]);
            });
            return [
                [
                    index,
                    item.AmazonOrderId,
                    item.PurchaseDate,
                    item.OrderStatus,
                    item.SalesChannel,
                    item.shipment_date,
                    item.report[0].net_profit,
                    item.refund_date,
                    item.report[0].refund_amount,
                    ...items,
                ],
            ];
        },
    },
};
</script>
<style lang="sass">
$order-filter-height: 120px
.order-list, .order-forecast-item-list
  > header
    > div
      > form
        > div
          > div:nth-child(2)
            display: none !important

.order-list
  border-right: 1px solid black
  > header
      height: $order-filter-height !important
      > div
          height: $order-filter-height !important
</style>
