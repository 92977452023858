<template>
    <div>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <va-list
                ref="list"
                :filters="filters"
                :filter.sync="filter"
                disable-export
                disable-create
                class="asin-profile-list"
                :items-per-page="100"
                :items-per-page-options="[100, 200, 300, 500]"
            >
                <va-data-table
                    ref="table"
                    class="main-table"
                    :fields="fields"
                    disable-clone
                    disable-select
                    disable-delete
                    disable-show
                    disable-edit-redirect
                    disable-actions
                >
                    <template
                        v-slot:[`field.AttributeSets[0]`]="{ value, item }"
                    >
                        <img
                            width="75"
                            v-if="value && value.SmallImage"
                            :alt="value.SmallImage.URL"
                            :src="getImage(item, value.SmallImage.URL)"
                            :onerror="`this.onerror=null;this.src='${value.SmallImage.URL}';`"
                        />
                    </template>
                    <template v-slot:[`field.ASIN`]="{ value, item }">
                        <a @click="openAsinProfileReport(item)">{{ value }}</a>
                    </template>
                    <template v-slot:[`field.label`]="{ value, item }">
                        <v-chip
                            text-color="white"
                            v-if="value"
                            :color="getLabelColor(value)"
                        >
                            {{ value }}
                        </v-chip>
                        - ({{ parseInt(item.sum_sale_30_day, 10) }})
                    </template>
                    <template v-slot:[`field.tags`]="{ value, item }">
                        <v-chip v-if="item.is_low_price">
                            {{ "LowPrice" }}
                        </v-chip>
                        <v-chip v-if="item.is_neg_gp">
                            {{ "NegGP" }}
                        </v-chip>
                    </template>
                    <template v-slot:[`field.created_at`]="{ value, item }">
                        <v-chip
                            text-color="white"
                            :color="getUploadStatusColor(item)"
                        >
                            {{ value | moment("timezone", sourceTz, "LLL") }}
                        </v-chip>
                        - {{ item.asin_age }}
                    </template>
                </va-data-table>
            </va-list>
            <v-dialog v-model="dialog">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            ASIN:
                            <a
                                v-if="selected"
                                target="_blank"
                                :href="'https://amazon.com/dp/' + selected.ASIN"
                                >{{ selected.ASIN }}</a
                            >
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-card-text v-if="!report">
                                    Loading...
                                    <v-progress-linear
                                        indeterminate
                                    ></v-progress-linear>
                                </v-card-text>
                                <div class="d-flex flex-column">
                                    <calendar-filter
                                        :custom-ranges="dateRanges"
                                        @update="
                                            (val) =>
                                                applyAsinProfileReportFilter(
                                                    val
                                                )
                                        "
                                    ></calendar-filter>

                                    <div class="my-4">
                                        <highcharts
                                            id="sale-chart"
                                            key="sale-chart"
                                            v-if="report"
                                            :options="chartOptions"
                                        ></highcharts>
                                    </div>

                                    <div class="my-4">
                                        <highcharts
                                            id="rank-chart"
                                            key="rank-chart"
                                            v-if="report"
                                            :options="rankChartOptions"
                                        ></highcharts>
                                    </div>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
        </base-material-card>
    </div>
</template>

<script>
import Export from "@/resources/asin-profile/Export";
import SaleInventoryLabels, {
    SaleInventoryLabelColorMap,
} from "@/constants/SaleInventoryLabels";
import UploadStatusColors from "@/constants/UploadStatusColors";
import { tz, sourceTz } from "@/constants/Time";
import { AsinAgeLabels } from "@/constants/AsinAgeTypes";
import CogCatalogTagTypes from "@/constants/CogCatalogItemTagTypes";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import CalendarFilter from "@/components/CalendarFilter.vue";
import dateRanges from "@/constants/DateRanges";
import moment from "moment-timezone";

export default {
    mixins: [Export],
    components: {
        CalendarFilter,
        highcharts: Chart,
    }, //eslint-disable-line
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["parent_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["parent_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "parent_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            selectedTab: 0,
            marketplaceIdKey: "main_marketplace_id",
            filter: {
                second_marketplace: 0,
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "parent_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    label: "SKU",
                    source: "CogCatalogItem.sku",
                    alwaysOn: true,
                },
                {
                    label: "ASIN",
                    source: "FbaInventory.asin",
                    alwaysOn: true,
                },
                {
                    label: "Supplier",
                    source: "supplier_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "Supplier",
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
                {
                    source: "SaleInventory.label",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: SaleInventoryLabels,
                    },
                    alwaysOn: true,
                },
                {
                    label: "Tags",
                    source: "tags",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: CogCatalogTagTypes,
                    },
                    alwaysOn: true,
                },
                {
                    label: "ASIN Age",
                    source: "SaleInventory.asin_age",
                    type: "select",
                    attributes: {
                        multiple: false,
                        choices: AsinAgeLabels,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                {
                    label: "Image",
                    source: "AttributeSets[0]",
                    sortable: false,
                },
                {
                    label: "SKU",
                    source: "sku",
                    type: "text",
                    sortable: false,
                },
                {
                    label: "ASIN",
                    source: "ASIN",
                    sortable: false,
                },
                { label: "Label", source: "label", sortable: false },
                { label: "Tags", source: "tags", sortable: false },
                {
                    label: "Supplier",
                    source: "supplier.name",
                    sortable: false,
                },
                {
                    label: "PIC",
                    source: "pic.name",
                    sortable: false,
                },
                {
                    label: "Quantity",
                    source: "currentQuantity",
                    sortable: true,
                },
                {
                    label: "Created at",
                    source: "created_at",
                    sortable: true,
                },
            ],
            dialog: false,
            selected: false,
            report: false,
            cachedReport: {},
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                xAxis: [
                    {
                        categories: [],
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        // Primary yAxis
                        title: {
                            text: "Price",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        labels: {
                            format: "{value}$",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        opposite: true,
                    },
                    {
                        // Secondary yAxis
                        labels: {
                            format: "{value} items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                        title: {
                            text: "Quantity",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                    },
                    {
                        // Third yAxis
                        title: {
                            text: "Sale item",
                            style: {
                                color: Highcharts.getOptions().colors[2],
                            },
                        },
                        labels: {
                            format: "{value} items",
                            style: {
                                color: Highcharts.getOptions().colors[2],
                            },
                        },
                        opposite: true,
                    },
                ],
                tooltip: {
                    shared: true,
                },
                legend: {
                    layout: "vertical",
                    align: "left",
                    x: 120,
                    verticalAlign: "top",
                    y: 100,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        "rgba(255,255,255,0.25)",
                },
                series: [
                    {
                        name: "Pricing",
                        type: "spline",
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: "$",
                        },
                    },
                    {
                        name: "Quantity",
                        type: "spline",
                        yAxis: 2,
                        data: [],
                        tooltip: {
                            valueSuffix: " items",
                        },
                    },
                    {
                        name: "Sale Item",
                        type: "spline",
                        data: [],
                        tooltip: {
                            valueSuffix: " items",
                        },
                    },
                ],
            },
            rankChartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                xAxis: [
                    {
                        categories: [],
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        title: {
                            text: "Sales Rank",
                            style: {
                                color: Highcharts.getOptions().colors[3],
                            },
                        },
                        labels: {
                            format: "{value}",
                            style: {
                                color: Highcharts.getOptions().colors[3],
                            },
                        },
                        opposite: true,
                    },
                ],
                tooltip: {
                    shared: true,
                },
                legend: {
                    layout: "vertical",
                    align: "left",
                    x: 120,
                    verticalAlign: "top",
                    y: 100,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        "rgba(255,255,255,0.25)",
                },
                series: [
                    {
                        name: "Sales Rank",
                        // yAxis: 1,
                        type: "column",
                        data: [],
                        tooltip: {
                            valueSuffix: " ",
                        },
                    },
                ],
            },
            dateRanges,
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["parent_id"] = id ? id : "";
            this.asideTitle = null;
            this.showEdit = false;
            this.showImport = false;
            this.showBulk = false;
            if (id) {
                const mwsConfigurationOption =
                    this.mwsConfigurationOptions.find(
                        (mwsConfiguration) => mwsConfiguration.value === id
                    );
                this.filter["marketplace_id"] =
                    mwsConfigurationOption[this.marketplaceIdKey];
            } else {
                this.filter["marketplace_id"] = "";
            }
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["parent_id"]) {
                return;
            }
            this.selectedTab = parseInt(currentFilter["parent_id"], 10);
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        openAsinProfileReport(item) {
            this.dialog = true;
            this.selected = item;
            this.applyAsinProfileReportFilter({
                startDate: moment(this.dateRanges["This month"][0]),
                endDate: moment(this.dateRanges["This month"][1]),
            });
        },
        async applyAsinProfileReportFilter(value) {
            const marketplace_id = this.filter["marketplace_id"];
            const filter = {
                asin: this.selected.ASIN,
                marketplace_id,
                start: value.startDate.clone().format("YYYY-MM-DD"),
                end: value.endDate.clone().format("YYYY-MM-DD"),
            };
            this.report = false;
            if (
                this.cachedReport[
                    `${filter.asin}_${filter.start}_${filter.end}_${marketplace_id}`
                ]
            ) {
                this.report =
                    this.cachedReport[
                        `${filter.asin}_${filter.start}_${filter.end}_${marketplace_id}`
                    ];
            } else {
                const response = await this.$store.dispatch(
                    "AsinProfileReport/getList",
                    {
                        filter,
                    }
                );
                this.report = response.data;
                this.cachedReport[
                    `${filter.asin}_${filter.start}_${filter.end}_${marketplace_id}`
                ] = { ...response.data };
            }

            this.chartOptions.xAxis[0].categories =
                this.report.total_quantity.map((i) => {
                    if (i.year) {
                        return moment()
                            .year(i.year)
                            .month(i.month - 1)
                            .tz(tz)
                            .format("MMM YYYY");
                    }
                    return moment(i.at_date).tz(tz).format("ll");
                });
            this.chartOptions.series[0].data =
                this.report.total_listing_price.map((i) => 1 * i.count);
            this.chartOptions.series[1].data = this.report.total_quantity.map(
                (i) => 1 * i.count
            );
            this.chartOptions.series[2].data = this.report.total_sale_item.map(
                (i) => 1 * i.count
            );

            this.rankChartOptions.xAxis[0].categories =
                this.report.total_sales_ranking.map((i) => {
                    if (i.year) {
                        return moment()
                            .year(i.year)
                            .month(i.month - 1)
                            .tz(tz)
                            .format("MMM YYYY");
                    }
                    return moment(i.at_date).tz(tz).format("ll");
                });
            this.rankChartOptions.series[0].data =
                this.report.total_sales_ranking.map((i) => 1 * i.count);
        },
        getLabelColor(label) {
            return SaleInventoryLabelColorMap[label];
        },
        getUploadStatusColor(item) {
            if (
                !item.barcode_file ||
                !item.image_file ||
                !item.original_price
            ) {
                return UploadStatusColors.ONE;
            }
            if (item.barcode_file && item.image_file && item.original_price) {
                return UploadStatusColors.BOTH;
            }
            return UploadStatusColors.NONE;
        },
        getImage(item, defaultImage) {
            if (!item.image_file) {
                return item.image_link || defaultImage;
            }
            return item.image_file || item.image_link;
        },
    },
};
</script>
<style lang="sass">
$filter-height: 150px
.asin-profile-list
    > header
        height: $filter-height !important

        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important

            > header
                > div
                    > form
                        > div
                            > div:nth-child(2)
                                display: none !important

    > form
        > div
            > div
                > table
                    > thead
                        > tr
                            > th:nth-child(1)
                                width: 116px

                            > th:last-child
                                width: 50px

                    > tbody
                        > tr
                            > td:nth-child(1)
                                button
                                    background: transparent !important

                            > td:nth-child(8), td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13), td:nth-child(14), td:nth-child(15)
                                text-align: right !important
</style>
