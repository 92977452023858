<template>
    <v-dialog
        width="600"
        v-model="dialog"
        @click:outside="$emit('input', false)"
    >
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="$emit('input', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    ORDER: {{ item.AmazonOrderId }}
                </v-toolbar-title>
            </v-toolbar>
            <div class="d-flex pa-4 align-center justify-center">
                <div class="d-flex flex-column" v-if="report">
                    <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="report"
                    >
                    </v-data-table>

                    <v-data-table
                        v-if="itemReport"
                        hide-default-footer
                        :headers="itemHeaders"
                        :items="itemReport"
                    >
                        <template v-slot:item.ImageAttribute="{ value, item }">
                            <img
                                width="75"
                                v-if="value && value.SmallImage"
                                :alt="value.SmallImage.URL"
                                :src="getImage(item, value.SmallImage.URL)"
                                :onerror="`this.onerror=null;this.src='${value.SmallImage.URL}';`"
                            />
                        </template>
                    </v-data-table>
                </div>
                <v-card-text v-else>
                    Loading...
                    <v-progress-linear indeterminate></v-progress-linear>
                </v-card-text>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "OrderReport",
    components: {},
    props: ["selected", "dialog"],
    model: {
        prop: "dialog",
        event: "input",
    },
    computed: {},
    data() {
        return {
            report: false,
            itemReport: false,
            cachedReport: {},
            item: { ...this.selected },
            headers: [
                {
                    text: "Shipment Date",
                    value: "shipment_date",
                    sortable: true,
                },
                {
                    text: "Net profit",
                    value: "net_profit",
                    sortable: true,
                },
                {
                    text: "Refund Date",
                    value: "refund_date",
                    sortable: true,
                },
                {
                    text: "Refund Amount",
                    value: "refund_amount",
                    sortable: true,
                },
            ],
            itemHeaders: [
                {
                    text: "ImageAttribute",
                    value: "ImageAttribute",
                    sortable: false,
                },
                {
                    text: "ASIN",
                    value: "ASIN",
                    sortable: true,
                },
                {
                    text: "SKU",
                    value: "sku",
                    sortable: true,
                },
                {
                    text: "Qty",
                    value: "qty",
                    sortable: true,
                },
                {
                    text: "Principal",
                    value: "principal",
                    sortable: true,
                },
            ],
        };
    },
    watch: {
        dialog(newVal) {
            if (!newVal) {
                return;
            }

            this.item = { ...this.selected };

            this.applyOrderReportFilter();
        },
    },
    methods: {
        async applyOrderReportFilter() {
            this.report = false;
            this.itemReport = false;
            if (this.cachedReport[this.item.id]) {
                this.report = [...this.cachedReport[this.item.id]];
                this.itemReport = [...this.report[0].items];
            } else {
                const response = await this.$store.dispatch("Order/getOne", {
                    id: this.item.id,
                    extraParams: {
                        report: 1,
                    },
                });
                this.report = response.data.report;
                this.itemReport = [...this.report[0].items];
                this.cachedReport[this.item.id] = [...response.data.report];
            }
        },
        getImage(item, defaultImage) {
            if (!item.image_file) {
                return item.image_link || defaultImage;
            }
            return item.image_file || item.image_link;
        },
    },
};
</script>
